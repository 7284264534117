.so-tripas {
    width: 80%;
    min-height: 50vh;
    background: white;
    margin: auto;
}

@media only screen and (max-width: 600px) {
    .so-tripas {
        width: 100%;
        min-height: 50vh;
        background: white;
        margin: 0;
    }
  }