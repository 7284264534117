.navigation {
  width: 100%;
  background: #343538;

  &__full-menu {
    width: 100%;
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
  }

  &__menu-item {
    padding: 15px 20px;

    &:hover {
      cursor: pointer;
      background-color: rgb(1, 141, 255);
    }
  }

  &__menu-link {
    color: white;
    text-decoration: none;
  }
}

.mobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .navigation{
    &__full-menu{
      display: none;
    }
  }
  .mobile {
    display: block;
    width: 100%;
    line-height: 50px;
    color: white;
    height: 50px;
    background: #343538;
    text-align: left;
    padding-left: 10px;
    font-weight: bold;
    font-size: 20px;

    &__menu {
      width: 100%;
      list-style: none;
      align-items: center;
      margin: 0;
      padding: 0;
    }

    &__menu-item {
      padding: 15px 20px;
  
      &:hover {
        cursor: pointer;
        background-color: rgb(1, 141, 255);
      }
    }
  
    &__menu-link {
      color: white;
      text-decoration: none;
    }
  }
}

