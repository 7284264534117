.header {
    width: 100%;
    display: flex;
    padding:0 50px;
    justify-content: space-between;
    align-items: center;

    &__punch-line{
        margin-right: 100px;
    }
}