.App {
  text-align: center;
  background-image: url('../public/assets/background.jpeg');
  min-height: 100vh;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
}


@media only screen and (max-width: 600px) {
  .App {
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }
}
