.page {
  &__slider {
    height: 350px;
    width: 100%;
    background-image: url("../../../public/assets/fumeiro.jpeg");
    background-size: cover;
  }

  &__paragraph {
    text-align: justify;
  }

  &__section {
    padding:0 30px 30px 30px;
  }

  &__bottom-image {
    width: 50%;
  }

  &__gallery {
    display: flex;
    flex-wrap: wrap;
  }

  &__product {
    &--thumb {
      width: 200px;
      height: 200px;
      margin: 10px;
      cursor: pointer;
    }
  }

  &__expanded {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    width: 100%;
    height: 100%;
    bottom: 0;
    overflow: hidden;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 0;
    color: white;
    font-size: 100px;
  }

  &__open-image {
    max-width: 70%;
    position: absolute;
    top: 25%;
    left: 15%;
  }

  &__contacts {
    display: flex;
    flex-wrap: wrap;
  }

  &__address {
    width: 50%;
  }
  
  &__form {
    width: 50%;
  }

  &__input {
    margin-top: 10px;
    width: 80%;
  }

  &__message {
    width: 80%;
    color: gray;
    background: greenyellow;
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 600px) {
  .page {
    &__contacts {
      display: block;
    }

    &__form {
      width: 100%;
    }

    &__address {
      margin-bottom: 50px;
      width: 100%;
    }
  }
}

