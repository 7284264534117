.footer {
    min-height: 100px;
    width: 100%;
    background: #343538;

    &__title{
        color: white;
        font-size: 25px;
    }

    &__parceiros {
        width: 100%;
        display: flex;
        justify-content: space-around;
        
        img {
            max-width: 250px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .footer{
        &__parceiros {
            width: 100%;
            display: flex;
            justify-content: space-around;
            
            img {
                max-width: 150px;
            }
        }
    }
}